import React from "react";
import { Table, TableData, TableRow } from "../../ui";

const detailedReasons: { [key: string]: string } = {
  REGULAR_HOURS: "Regular business hours",
  OPEN: "Regular business hours",
  OUT_OF_MENU_HOURS: "Outside of business hours",
  CLOSED: "Outside of business hours",
  INVISIBLE: "Not visible in app",
  PAUSED_BY_UBER: "Paused by Uber",
  PAUSED_BY_RESTAURANT: "Paused by restaurant",
  PERM_DEACTIVATED: "Permanently deactivated",
  TEMP_DEACTIVATED: "Temporarily deactivated",
  TEMPORARILY_UNAVAILABLE: "Temporarily deactivated",
  TEMPORARILY_AVAILABLE: "Temporarily activated",
  UNAVAILABLE: "Failed to fetch",
  PUASED: "Paused",
};

type Provider = {
  id: number;
  name: string;
  slug: string;
};

type StoreStatusProps = {
  data: any;
  providers: Provider[];
  currentLocation: string;
  onActivateStores: (store_ids: any, provider: string) => void;
  onDeactivateStores: (store_ids: any, provider: string) => void;
};

const locationNames: { [key: string]: string } = {
  sanjose: "San Jose",
  menlopark: "Menlo Park",
  lafayette: "Lafayette",
  cupertino: "Cupertino",
};

const basicButton =
  "font-bold uppercase text-sm px-4 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1";
const disabledButton =
  "text-gray-500 w-28 font-bold uppercase text-sm px-4 py-1 rounded shadow outline-none focus:outline-none mr-1 mb-1";

const getIsActive = (
  providerSlug: string,
  store: any
): {
  isActive: boolean;
  displayStatus: string;
} => {
  let isActive = store[`${providerSlug}_store_status`].is_open;

  // We special case display status for GrubHub. When the store is "CLOSED",
  // activating and deactivating are no-ops from our end. Therefore, we show
  // is_open from the store (our intent). This avoids confusion in the UI where
  // Uber Eats and DoorDash might be out of hours  but deactivated and thus show
  // up DEACTIVATED in the UI, but GrubHub, which may also be out of hours, shows
  // up ACTIVATED.
  if (
    providerSlug === "grubhub" &&
    store[`${providerSlug}_store_status`].details === "CLOSED"
  ) {
    isActive = store.is_open;
  }

  let displayStatus = isActive ? "ACTIVATED" : "DEACTIVATED";

  return {
    isActive,
    displayStatus,
  };
};

export const StoreStatus = ({
  data,
  providers,
  currentLocation,
  onActivateStores,
  onDeactivateStores,
}: StoreStatusProps) => {
  return (
    <div className="w-full h-screen overflow-x-hidden flex flex-col text-gray-900">
      <main className="w-full grow">
        <div className="w-full">
          <div className="flex justify-end">
            <div className="mr-2">{locationNames[currentLocation]}</div>
          </div>
          <div>
            <div>
              <Table>
                <TableRow>
                  <TableData />
                  <TableData textAlign="text-center">Store Status</TableData>
                  {providers?.map((provider: Provider, i: number) => (
                    <TableData key={`provider-${i}`} textAlign="text-center">
                      {provider.name}
                    </TableData>
                  ))}
                </TableRow>
                {data.stores?.map((store: any, i: number) => {
                  return (
                    <TableRow>
                      <TableData key={`brand-${i}`}>
                        {store.brand.ticker}
                      </TableData>
                      <TableData textAlign="text-center" key={`active-${i}`}>
                        {store.is_open ? (
                          <button
                            onClick={() =>
                              onDeactivateStores([store.id], "all")
                            }
                            className={`${basicButton} bg-green-500 text-green-100 w-40`}
                          >
                            Activated
                          </button>
                        ) : (
                          <button
                            onClick={() => onActivateStores([store.id], "all")}
                            className={`${basicButton} bg-red-500 text-red-100  w-40`}
                          >
                            Deactivated
                          </button>
                        )}
                      </TableData>
                      {providers?.map((provider: Provider, j: number) => {
                        if (!store[`${provider.slug}_store_id`])
                          return <TableData key={`status-${i}-${j}`} />;

                        const statusDetails =
                          store[`${provider.slug}_store_status`].details;
                        const { isActive, displayStatus } = getIsActive(
                          provider.slug,
                          store
                        );
                        const isDisabled =
                          store[`${provider.slug}_store_status`].details ===
                          "UNAVAILABLE";

                        return (
                          <TableData
                            key={`status-${i}-${j}`}
                            textAlign="text-center"
                          >
                            <div className="flex flex-col">
                              <div>
                                {isDisabled && (
                                  <button className={disabledButton} disabled>
                                    UNKNOWN
                                  </button>
                                )}
                                {!isDisabled && isActive && (
                                  <button
                                    className={`${basicButton} text-green-400 w-28`}
                                    onClick={() =>
                                      onDeactivateStores(
                                        [store.id],
                                        provider.slug
                                      )
                                    }
                                  >
                                    {displayStatus}
                                  </button>
                                )}
                                {!isDisabled && !isActive && (
                                  <button
                                    className={`${basicButton} text-red-400 w-28`}
                                    onClick={() =>
                                      onActivateStores(
                                        [store.id],
                                        provider.slug
                                      )
                                    }
                                  >
                                    {displayStatus}
                                  </button>
                                )}
                              </div>
                              <div className="text-gray-400 italic">
                                {detailedReasons[statusDetails] || "N/A"}
                              </div>
                            </div>
                          </TableData>
                        );
                      })}
                    </TableRow>
                  );
                })}
                <TableRow>
                  <TableData />
                  <TableData>
                    <div className="flex flex-col items-center">
                      <button
                        onClick={() =>
                          onActivateStores(
                            data.stores?.map((store: any) => store.id),
                            "all"
                          )
                        }
                        className={`${basicButton} bg-green-500 text-green-100 mb-8 w-40`}
                      >
                        Activate All
                      </button>
                      <button
                        onClick={() =>
                          onDeactivateStores(
                            data.stores?.map((store: any) => store.id),
                            "all"
                          )
                        }
                        className={`${basicButton} bg-red-500 text-red-100 w-40`}
                      >
                        Deactivate All
                      </button>
                    </div>
                  </TableData>
                  {providers?.map((provider: Provider, i: number) => (
                    <TableData key={`empty-${i}`} textAlign="text-center" />
                  ))}
                </TableRow>
              </Table>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
