import { useQuery, gql } from "@apollo/client";

export const STORES_QUERY = gql`
  query StoresQuery($location_slug: String, $force: Boolean) {
    stores(location_slug: $location_slug) {
      id
      brand {
        id
        name
        ticker
      }
      doordash_store_id
      ubereats_store_id
      grubhub_store_id
      is_open
      doordash_store_status(force: $force) {
        is_open
        details
      }
      grubhub_store_status(force: $force) {
        is_open
        details
      }
      ubereats_store_status(force: $force) {
        is_open
        details
      }
    }
  }
`;

export const useStores = (locationSlug: string) => {
  const { data, error, loading, refetch } = useQuery(STORES_QUERY, {
    variables: { location_slug: locationSlug },
    pollInterval: 2 * 60 * 1000,
    fetchPolicy: "cache-and-network",
  });
  return { data, error, loading, refetch };
};
