import React from "react";

export type TableDataProps = {
  title?: string;
  children?: React.ReactNode;
  backgroundColor?: string;
  textAlign?: string;
  onClick?: (event: any) => void;
};

export const TableData = ({
  children,
  title,
  onClick,
  backgroundColor,
  textAlign,
}: TableDataProps) => {
  const bgColor = backgroundColor ? `bg-${backgroundColor}-500` : "bg-white";
  return (
    <td
      onClick={onClick}
      className={`px-5 py-5 border-b border-gray-200 ${bgColor} hover:bg-gray-100 transition duration-100 ease-in-outtext-sm ${
        textAlign || ""
      } ${onClick ? "cursor-pointer" : ""}}`}
    >
      {title ? title : children}
    </td>
  );
};
