import React from "react";

export type TableRowProps = {
  onClick?: () => void;
  children: React.ReactNode | Array<React.ReactNode>;
};

export const TableRow = ({ children, onClick }: TableRowProps) => {
  return (
    <tr
      className={`px-5 py-5 border-b border-gray-200 bg-white text-sm ${
        onClick ? "cursor-pointer" : ""
      }}`}
      onClick={onClick}
    >
      {children}
    </tr>
  );
};
