import { useMutation, gql } from "@apollo/client";
import { STORES_QUERY } from "./useStores";

const ACTIVATE_STORES = gql`
  mutation ActivateStores($store_ids: [Int], $provider: String) {
    activate_stores(store_ids: $store_ids, provider: $provider) {
      id
    }
  }
`;

const DEACTIVATE_STORES = gql`
  mutation DeactivateStore($store_ids: [Int], $provider: String) {
    deactivate_stores(store_ids: $store_ids, provider: $provider) {
      id
    }
  }
`;

export const useActivateStores = () => {
  const [activateStores] = useMutation(ACTIVATE_STORES);
  const [deactivateStores] = useMutation(DEACTIVATE_STORES);

  const onActivateStores = async (storeIds: number[], provider: string) => {
    analytics.track("Activated Stores", {
      storeIds,
      provider,
    });

    await activateStores({
      variables: {
        store_ids: storeIds,
        provider,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: STORES_QUERY,
          variables: { store_ids: storeIds, force: true },
        },
      ],
    });
  };

  const onDeactivateStores = async (storeIds: number[], provider: string) => {
    analytics.track("Deactivated Stores", {
      storeIds,
      provider,
    });

    await deactivateStores({
      variables: {
        store_ids: storeIds,
        provider,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: STORES_QUERY,
          variables: { store_ids: storeIds, force: true },
        },
      ],
    });
  };

  return [onActivateStores, onDeactivateStores];
};
