import { useQuery, gql } from "@apollo/client";

export const PROVIDERS_QUERY = gql`
  query ProvidersQuery {
    providers {
      id
      name
      slug
    }
  }
`;

export const useProviders = () => {
  const { data, error, loading, refetch } = useQuery(PROVIDERS_QUERY);
  return { data, error, loading, refetch };
};
