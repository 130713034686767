import React from "react";
import { Loading } from "../../ui";

import { useActivateStores } from "./hooks/useActivateStores";
import { useProviders } from "./hooks/useProviders";
import { useStores } from "./hooks/useStores";
import { StoreStatus } from "./StoreStatus";

type StoreStatusControllerProps = {
  currentLocation: string;
};

export const StoreStatusController = ({
  currentLocation,
}: StoreStatusControllerProps) => {
  const { data, loading } = useStores(currentLocation);
  const { data: providersData, loading: providersLoading } = useProviders();
  const [onActivateStores, onDeactivateStores] = useActivateStores();

  if (loading || providersLoading) return <Loading />;

  if (!data || !providersData) return null;

  return (
    <StoreStatus
      onActivateStores={onActivateStores}
      onDeactivateStores={onDeactivateStores}
      data={data}
      providers={(providersData.providers || []).filter(
        (provider: any) =>
          ![
            "revel",
            "localkitchens",
            "mobile",
            "kiosk",
            "guest-kiosk",
          ].includes(provider.slug)
      )}
      currentLocation={currentLocation}
    />
  );
};
